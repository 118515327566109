<template>
    <div class="icharge">
        <!-- banner -->
        <div class="banner">
            <div class="content df-sb-cen">
                <div class="left">
                    <div class="title">{{ data.charge_banner && data.charge_banner.content.list[0].text }}</div>
                    <div class="tip">
                        {{ data.charge_banner && data.charge_banner.content.list[0].content }}
                    </div>
                    <div class="btn" @click="goDownload" @mouseover="showCode" @mouseout="showCode">
                        商戶免費使用<i class="el-icon-arrow-right"></i>
                        <div v-show="codePop" class="popup" v-if="init.charge_merchant_code && init.charge_merchant_code.content">
                            <img :src="init.charge_merchant_code.content" alt="" />
                            <p>i充電商戶版</p>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <img :src="data.charge_banner && data.charge_banner.content.list[0].image" alt="" />
                </div>
            </div>
        </div>

        <!-- warp-one -->
        <div class="warp warp-one">
            <div class="content">
                <div class="title">{{ data.charge_benefit && data.charge_benefit.content.text }}</div>
                <div class="tip">{{ data.charge_benefit && data.charge_benefit.content.content }}</div>
                <ul class="df">
                    <li v-for="(item, index) in data.charge_benefit.content.list" :key="index">
                        <img :src="item.image" alt="" />
                        <div class="sub_title">{{ item.text }}</div>
                        <div class="sub_tip">{{ item.content }}</div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- warp-two -->
        <div class="warp warp-two">
            <div class="img-context" v-for="(item, index) in data.charge_advantage.content.list" :key="index">
                <div class="content df-cen-cen">
                    <div class="img">
                        <img :src="item.image" alt="" />
                    </div>
                    <div class="context">
                        <div class="title">{{ item.text }}</div>
                        <div class="tip">{{ item.content }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- warp-three -->
        <div class="warp warp-three">
            <div class="content">
                <div class="title">{{ data.charge_function && data.charge_function.content.text }}</div>
                <div class="tip">{{ data.charge_function && data.charge_function.content.content }}</div>
                <div class="item-box df">
                    <div class="item df" v-for="(item, index) in data.charge_function.content.list" :key="index">
                        <div class="item-icon">
                            <img :src="item.image" alt="" />
                        </div>
                        <div class="item-text">
                            <div class="item-text-title">{{ item.text }}</div>
                            <div class="item-text-tip">{{ item.content }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- warp-four -->
        <div class="warp warp-four">
            <div class="content">
                <div class="title">{{ data.charge_manage && data.charge_manage.content.text }}</div>
                <ul class="df-cen-cen">
                    <li v-for="(item, index) in data.charge_manage.content.list" :key="index">
                        <img :src="item.image" alt="" />
                        <div class="sub_title">{{ item.text }}</div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- warp-five -->
        <div class="warp warp-five">
            <div class="content">
                <div class="title">{{ init.merchant && init.merchant.content }}</div>
                <ul class="df-cen-cen">
                    <li v-for="(item, index) in init.merchant_list.content" :key="index"><img :src="item" alt="" /></li>
                </ul>
            </div>
        </div>
        <!-- warp-six -->
        <div class="warp warp-six">
            <div class="content">
                <div class="title">{{ data.device && data.device.title }}</div>
                <div class="center df-cen">
                    <img :src="data.device.content[0].image" alt="" />
                    <div class="context df-col">
                        <div v-html="data.device.content[0].text"></div>
                        <div v-html="data.device.content[1].text"></div>
                        <img :src="data.device.content[1].image" alt="" />
                    </div>
                </div>
                <div class="list-item">
                    <div class="sub_title">{{ data.protect && data.protect.title }}</div>
                    <ul class="df">
                        <li v-for="(item, index) in data.protect.content" :key="index">
                            <img :src="item.image[0]" alt="" />
                            <p>{{ item.text }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- warp-seven -->
        <div class="warp warp-seven">
            <div class="content">
                <div class="title">{{ data.cabinet_style && data.cabinet_style.title }}</div>
                <ul>
                    <li class="df-cen-cen" v-for="(item, index) in data.cabinet_style.content.list" :key="index">
                        <img :src="item.image" alt="" />
                        <div class="context">
                            <div class="name">{{ item.text }}</div>
                            <div class="list df">
                                <div class="item df-col-cen-cen" v-for="(ite, ind) in item.list" :key="ind">
                                    <div class="item-name">{{ ite.text1 }}</div>
                                    <div class="item-value">{{ ite.text2 }}</div>
                                </div>
                            </div>
                            <div class="tips" v-html="item.content"></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- warp-night -->
        <div class="warp warp-night">
            <div class="content df-col-cen-cen">
                <div class="title">{{ data.merchant_join && data.merchant_join.title }}</div>
                <div class="tip">{{ data.merchant_join && data.merchant_join.content }}</div>
                <ul class="df-sb-cen">
                    <li v-for="(item, index) in data.device_list && data.device_list.content" :key="index">
                        <img :src="item" alt="" />
                    </li>
                </ul>
                <div class="btn" @click="goDownload" @mouseover="showCode" @mouseout="showCode">
                    商戶免費使用<i class="el-icon-arrow-right"></i>
                    <div v-show="codePop" class="popup" v-if="init.charge_merchant_code && init.charge_merchant_code.content">
                        <img :src="init.charge_merchant_code.content" alt="" />
                        <p>i充電商戶版</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- warp-ten -->
        <div class="warp warp-ten">
            <div class="content">
                <div class="title">{{ data.problems && data.problems.title }}</div>
                <ul>
                    <li class="df-cen-cen" v-for="(item, index) in data.problems.content.list" :key="index">
                        <div class="left">
                            <div class="sub_title">{{ item.text }}</div>
                            <div class="sub_tip" v-html="item.content"></div>
                        </div>
                        <div class="right"></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            data: 'app/ichargeData',
            init: 'app/initData',
        }),
    },
    data() {
        return {
            codePop: false,
            mobile: (window.innerWidth || document.documentElement.clientWidth || document.body.clientHclientWidtheight) <= 768,
        };
    },
    created() {
        window.addEventListener('resize', () => this.isMobile());
    },
    beforeDestroy() {
        window.removeEventListener('resize', () => this.isMobile());
    },
    methods: {
        showCode() {
            if (this.mobile) return;
            this.codePop = !this.codePop;
        },
        goDownload() {
            if (this.mobile)
                this.$router.push({
                    name: 'icharge-merchant-download',
                });
        },
        isMobile() {
            this.mobile = (window.innerWidth || document.documentElement.clientWidth || document.body.clientHclientWidtheight) <= 768;
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
    .icharge {
        .btn {
            min-width: 176px;
            width: 176px;
            height: 49px;
            background: var(--theme-color);
            border-radius: 4px;
            color: #ffffff;
            line-height: 49px;
            text-align: center;
            font-size: 18px;

            font-weight: bold;
            position: relative;
            .popup {
                position: absolute;
                background: rgba(183, 245, 245, 0.39);
                left: 200px;
                top: -25px;
                width: 212px;
                min-height: 200px;
                padding: 16px 25px 0;
                img {
                    width: 100%;
                }
                p {
                    font-size: 16px;
                    color: #333333;
                }
                &::before {
                    content: '';
                    border: 10px solid rgba(255, 244, 211, 0.39);
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    border-right-color: rgba(255, 244, 211, 0.39);
                    position: absolute;
                    left: -20px;
                    top: 40px;
                }
            }
        }
        .banner {
            padding: 100px 0 150px;
            background: #44d4d4;
            .left {
                flex: 1;
                .title {
                    font-weight: bold;
                    color: #ffffff;
                    font-size: 50px;
                }
                .tip {
                    font-size: 16px;
                    color: #ffffff;
                    margin: 32px 0;
                }
            }
            .right {
                flex: 1;
                img {
                    width: 100%;
                }
            }
        }
        .warp {
            padding: 60px 0;
            .title {
                font-size: 32px;

                font-weight: bold;
                font-weight: bold;
                margin: 10px 0;
                text-align: center;
            }
            .tip {
                font-size: 16px;

                margin: 10px 0;
                text-align: center;
                width: 50%;
                margin: 0 auto;
            }
        }
        .warp-one {
            ul {
                margin-top: 60px;
                li {
                    text-align: center;
                    flex: 1;
                    img {
                        width: 200px;
                        height: 200px;
                        margin: 20px 0;
                    }
                    .sub_title {
                        margin: 10px 0;
                        font-size: 24px;

                        font-weight: bold;
                    }
                    .sub_tip {
                        margin: 10px 0;
                        font-size: 16px;
                        width: 70%;
                        margin: 0 auto;
                    }
                    &:not(:first-child):not(:last-child) {
                        margin: 0 20px;
                    }
                }
            }
        }
        .warp.warp-two {
            padding: 0 !important;
        }
        .warp-two {
            .img-context {
                padding: 40px;
                .content {
                    width: 950px;
                    .img {
                        flex: 1;
                        img {
                            width: 330px;
                        }
                    }
                    .context {
                        padding: 0 50px;
                        .title {
                            text-align: left !important;
                            font-weight: bold;
                        }
                        .tip {
                            text-align: left !important;
                            width: 100%;
                        }
                    }
                }
                &:nth-child(2n) {
                    background: #8bd1cd;
                    .content {
                        flex-direction: row-reverse;
                    }
                }
                &:nth-child(2n + 1) {
                    background: #96d7d7;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                }
            }
        }
        .warp-three {
            .item-box {
                margin-top: 60px;
                flex-wrap: wrap;
                .item {
                    width: 300px;
                    max-width: 300px;
                    padding: 0 8px;
                    margin-bottom: 60px;
                    .item-icon {
                        margin-right: 16px;
                        img {
                            width: 60px;
                        }
                    }
                    .item-text {
                        &-title {
                            font-size: 24px;
                            margin-bottom: 10px;
                            font-weight: bold;
                        }
                        &-tip {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .warp-four {
            background: #96d7d7;
            ul {
                margin-top: 60px;
                li {
                    img {
                        width: 200px;
                        margin: 0 auto;
                        display: block;
                    }
                    .sub_title {
                        font-size: 24px;

                        font-weight: bold;
                        margin: 24px 0 10px;
                        text-align: center;
                    }
                    &:nth-child(2) {
                        margin: 0 60px;
                    }
                }
            }
        }
        .warp-five {
            ul {
                margin-top: 60px;
                li {
                    padding: 0 10px;
                    img {
                        width: 80px;
                    }
                }
            }
        }
        .warp-six {
            background: #96d7d7;
            .content {
                .center {
                    margin-top: 60px;
                    img {
                        width: 50%;
                    }
                    .context {
                        font-size: 16px;
                        color: #404040;
                        div:nth-of-type(2) {
                            margin: 33px 0;
                        }
                    }
                }
                .list-item {
                    text-align: center;
                    .sub_title {
                        margin: 60px 0;
                        font-size: 24px;
                        color: #404040;

                        font-weight: bold;
                    }
                    ul {
                        padding: 0 200px;
                        flex-wrap: wrap;
                        li {
                            width: 200px;
                            margin-bottom: 34px;
                            img {
                                width: 120px;
                                margin-bottom: 16px;
                            }
                            p {
                                font-size: 24px;
                                font-weight: 400;
                                color: #066963;

                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
        .warp-seven {
            ul {
                margin-top: 60px;
                li {
                    padding: 40px 0;
                    border-bottom: 1px solid #eaeaea;
                    &:last-child {
                        border-bottom: none;
                    }
                    img {
                        width: 250px;
                    }
                    .context {
                        margin-left: 68px;
                        .name {
                            color: #44bcb9;
                            font-size: 24px;
                            font-weight: bold;
                        }
                        .list {
                            margin: 34px 0 32px;
                            .item {
                                color: #404040;
                                background: #f9f9f9;
                                border-radius: 16px;
                                padding: 27px;
                                margin-left: 8px;
                                &-name {
                                    margin-bottom: 8px;
                                    font-size: 16px;
                                }
                                &-value {
                                    font-size: 24px;
                                    font-weight: bold;
                                }
                            }
                        }
                        .tips {
                            font-size: 16px;
                            color: #404040;
                        }
                    }
                }
            }
        }
        .warp-eight {
            background: #ffe999;
            .tip {
                width: 100%;
            }
            ul {
                margin-top: 60px;
                li {
                    flex: 1;
                    padding: 0 10px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .warp-night {
            background: #96d7d7;
            ul {
                margin: 60px 0;

                img {
                    width: 250px;
                }
            }
            .btn {
                background: #ffaa11;
            }
        }
        .warp-ten {
            background: #ffffff;
            .content {
                width: 600px;
                ul {
                    margin-top: 60px;

                    li {
                        padding: 16px 0;
                        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
                        margin: 16px 0;
                    }
                }
                .left {
                    flex: 1;
                    padding-right: 20px;
                    .sub_title {
                        font-size: 24px;

                        font-weight: bold;
                        color: #000000;
                        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
                        margin-bottom: 8px;
                    }
                    .sub_tip {
                        font-size: 16px;

                        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
                        & >>> a {
                            font-size: 12px;
                            text-decoration: underline;
                        }
                    }
                }
                .right {
                    width: 16px;
                    height: 0px;
                    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
                    border: 2px solid #ffaa11;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .icharge {
        .content {
            width: 90%;
        }
        .btn {
            margin: 0 auto;
            min-width: 50%;
            width: 50%;
            height: 49px;
            background: var(--theme-color);
            border-radius: 4px;
            color: #ffffff;
            line-height: 49px;
            text-align: center;
            font-size: 18px;

            font-weight: bold;
            position: relative;
            .popup {
                position: absolute;
                background: rgba(183, 245, 245, 0.39);
                left: 200px;
                top: -25px;
                width: 212px;
                min-height: 200px;
                padding: 16px 25px 0;
                img {
                    width: 100%;
                }
                p {
                    font-size: 16px;
                    color: #333333;
                }
                &::before {
                    content: '';
                    border: 10px solid rgba(255, 244, 211, 0.39);
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    border-right-color: rgba(255, 244, 211, 0.39);
                    position: absolute;
                    left: -20px;
                    top: 40px;
                }
            }
        }
        .banner {
            padding: 60px 0 80px;
            background: #44d4d4;
            .content {
                flex-direction: column-reverse;
            }
            .left {
                .title {
                    font-weight: bold;
                    color: #ffffff;
                    font-size: 24px;
                    text-align: center;
                }
                .tip {
                    font-size: 16px;
                    color: #ffffff;
                    margin: 32px 0;
                }
            }
            .right {
                img {
                    width: 100%;
                }
            }
        }
        .warp {
            padding: 60px 0;
            .title {
                font-size: 22px;

                font-weight: bold;
                font-weight: bold;
                margin: 10px 0;
                text-align: center;
            }
            .tip {
                font-size: 16px;

                margin: 10px 0;
                text-align: center;
                width: 100%;
                margin: 0 auto;
            }
        }
        .warp-one {
            ul {
                margin-top: 60px;
                flex-direction: column;
                li {
                    margin-bottom: 60px;
                    text-align: center;
                    flex: 1;
                    img {
                        width: 200px;
                        height: 200px;
                        margin: 20px 0;
                    }
                    .sub_title {
                        margin: 10px 0;
                        font-size: 24px;
                        font-weight: 600;

                        font-weight: bold;
                    }
                    .sub_tip {
                        margin: 10px 0;
                        font-size: 16px;
                        width: 70%;
                        margin: 0 auto;
                    }
                }
            }
        }
        .warp.warp-two {
            padding: 0 !important;
        }
        .warp-two {
            .img-context {
                .content {
                    flex-direction: column;
                    padding: 60px 0;
                    .img {
                        flex: 1;
                        img {
                            width: 100%;
                        }
                    }
                    .context {
                        .title {
                            font-weight: bold;
                        }
                        .tip {
                        }
                    }
                }
                &:nth-child(2n) {
                    background: #8bd1cd;
                }
                &:nth-child(2n + 1) {
                    background: #96d7d7;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                }
            }
        }
        .warp-three {
            .item-box {
                margin-top: 60px;
                flex-wrap: wrap;
                .item {
                    width: 50%;
                    margin-bottom: 60px;
                    flex-direction: column;
                    text-align: center;
                    .item-icon {
                        img {
                            width: 60px;
                        }
                    }
                    .item-text {
                        &-title {
                            font-size: 24px;
                            margin: 16px 0 10px;
                            font-weight: bold;
                        }
                        &-tip {
                            font-size: 16px;
                        }
                    }
                    &:nth-child(2n) {
                        padding-left: 16px;
                    }
                    &:nth-child(2n + 1) {
                        padding-right: 16px;
                    }
                }
            }
        }
        .warp-four {
            background: #96d7d7;
            ul {
                margin-top: 60px;
                flex-direction: column;
                li {
                    img {
                        width: 50%;
                        margin: 0 auto;
                        display: block;
                    }
                    .sub_title {
                        font-size: 20px;

                        font-weight: bold;
                        margin: 24px 0 10px;
                        text-align: center;
                    }
                    &:not(:first-child) {
                        margin-top: 60px;
                    }
                }
            }
        }
        .warp-five {
            ul {
                width: 100%;
                margin-top: 60px;
                flex-wrap: wrap;
                li {
                    width: 40%;
                    padding: 0 5%;
                    margin-bottom: 60px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .warp-six {
            background: #96d7d7;
            .content {
                .center {
                    margin-top: 60px;
                    flex-direction: column;
                    img {
                        width: 100%;
                    }
                    .context {
                        font-size: 16px;
                        color: #404040;
                        div:nth-of-type(2) {
                            margin: 33px 0;
                        }
                    }
                }
                .list-item {
                    text-align: center;
                    .sub_title {
                        margin: 60px 0;
                        font-size: 24px;
                        color: #404040;
                        font-weight: 600;

                        font-weight: bold;
                    }
                    ul {
                        flex-wrap: wrap;
                        li {
                            width: 50%;
                            padding: 0 8%;
                            margin-bottom: 34px;
                            img {
                                width: 100%;
                                margin-bottom: 16px;
                            }
                            p {
                                font-size: 24px;
                                font-weight: 400;
                                color: #066963;

                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
        .warp-seven {
            ul {
                li {
                    padding: 60px 0;
                    border-bottom: 1px solid #eaeaea;
                    flex-direction: column;
                    &:last-child {
                        border-bottom: none;
                    }
                    img {
                        width: 71%;
                    }
                    .context {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        .name {
                            color: #44bcb9;
                            font-size: 20px;
                            font-weight: bold;
                            text-align: center;
                            margin: 32px 0;
                        }
                        .list {
                            margin: 34px 0 32px;

                            width: 100%;
                            .item {
                                width: 30%;
                                margin: 0 1%;
                                padding: 28px 0;
                                color: #404040;
                                background: #f9f9f9;
                                border-radius: 16px;
                                &-name {
                                    margin-bottom: 8px;
                                    font-size: 16px;
                                    text-align: center;
                                }
                                &-value {
                                    font-size: 20px;
                                    font-weight: bold;
                                }
                                &:last-child {
                                    width: 34%;
                                }
                            }
                        }
                        .tips {
                            font-size: 16px;
                            color: #404040;
                        }
                    }
                }
            }
        }
        .warp-eight {
            background: #ffe999;
            .tip {
                width: 100%;
            }
            ul {
                margin-top: 60px;
                li {
                    flex: 1;
                    padding: 0 10px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .warp-night {
            background: #96d7d7;
            ul {
                margin: 60px 0;
                flex-direction: column;
                li {
                    margin-bottom: 32px;
                }
                img {
                    width: 60%;
                    margin: 0 auto;
                    display: block;
                }
            }
            .btn {
                background: #ffaa11;
            }
        }
        .warp-ten {
            background: #ffffff;
            .content {
                ul {
                    margin-top: 60px;

                    li {
                        padding: 16px 0;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                        margin: 16px 0;
                    }
                }
                .left {
                    flex: 1;
                    padding-right: 20px;
                    .sub_title {
                        font-size: 24px;

                        font-weight: bold;
                        color: #000000;
                        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
                        margin-bottom: 8px;
                    }
                    .sub_tip {
                        font-size: 16px;

                        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
                        & >>> a {
                            font-size: 12px;
                            text-decoration: underline;
                        }
                    }
                }
                .right {
                    width: 16px;
                    height: 0px;
                    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
                    border: 2px solid #ffaa11;
                }
            }
        }
    }
}
</style>
